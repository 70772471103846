<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/admin/sys_config_collegemanpower_report">
        <span>กำหนดการ</span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>
      <v-btn to="/admin/jor18">
        <span>จัดการเลขที่ตำแหน่ง</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>
      <v-btn to="/admin/jor18_report">
        <span>การรายงาน</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>
      <v-btn to="/admin/college_manpower">
        <span>การรายงาน(เลขที่ตำแหน่ง)</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn>
      
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "HRvecPersonnelmenu",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
  computed: {
    color() {
      return "#202224";
    }
  }
};
</script>

<style lang="scss" scoped></style>
